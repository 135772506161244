import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { createElement } from 'react'
import { RootStateType } from 'store/store.ts'
import { UnderConstruction } from 'components/AbstractComponents/UnderConstruction/UnderConstruction.tsx'

export const SUPPLY_ROUTES: Array<RouteObject> = [
    {
        index: true,
        element: <Navigate to={ 'warehouses' } />,
    },
    {
        id: 'warehouses',
        path: 'warehouses',
        element: <Outlet />,
        loader: () => ( {
            name: 'Список складов',
            path: 'supply/warehouses',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { WarehousesTable } = await import('components/Supply/Warehouses/WarehousesTable')
                    return { element: <WarehousesTable /> }
                },
            },
            {
                id: 'warehouseCreate',
                path: 'new',
                async lazy() {
                    const { WarehousePage } = await import('components/Supply/Warehouses/WarehousePage')
                    return { element: <WarehousePage /> }
                },
                loader: () => ( {
                    name: 'Новый склад',
                    path: 'supply/warehouses/new',
                } ),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { WarehousePageBodyEdit } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyEdit.tsx')
                            return { element: <WarehousePageBodyEdit /> }
                        },
                    },
                ],
            },
            {
                id: 'warehouseById',
                path: ':warehouseId',
                async lazy() {
                    const { WarehousePage } = await import('components/Supply/Warehouses/WarehousePage')
                    return { element: <WarehousePage /> }
                },
                loader: ({ params }) => ( {
                    id: params.warehouseId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                        name: state.warehousesReducer.editableFormHeader.name || '',
                    } ))),
                    path: `supply/warehouses/${ params.warehouseId }/about`,
                } ),
                children: [
                    {
                        index: true,
                        element: <Navigate to={ 'about' } replace />,
                    },
                    {
                        id: 'warehouseInfo',
                        path: 'about',
                        async lazy() {
                            const { WarehousePageBodyView } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyView.tsx')
                            return { element: <WarehousePageBodyView /> }
                        },
                    },
                    {
                        id: 'warehouseDocumentMovements',
                        path: 'document-movements',
                        loader: ({ params }) => ({
                            name: 'Перемещения',
                            path: `supply/warehouses/${params.warehouseId}/document-movements`,
                        }),
                        children: [
                            {
                                id: 'warehouseDocumentMovementsList',
                                path: '',
                                async lazy() {
                                    const { WarehouseDocumentMovementsTable } = await import('components/Supply/Warehouses/PageBody/DocumentMovements/WarehouseDocumentMovementsTable.tsx')
                                    return { element: <WarehouseDocumentMovementsTable /> }
                                },
                            },
                            {
                                id: 'warehouseDocumentMovementById',
                                path: ':movementId',

                                async lazy() {
                                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                                    return { element: <DocumentMovementPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.movementId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.documentMovementMaterialsReducer.documentMovementData?.name || '',
                                    }))),
                                    path: `supply/document-movements/${ params.movementId }`,
                                }),
                                children: [   {
                                    index: true,
                                    element: <Navigate to={ 'materials' } replace />,
                                },
                                {
                                    id: 'warehouseDocumentMovementMaterials',
                                    path: 'materials',
                                    loader: () => ({ active: 'materials' }),
                                    async lazy() {
                                        const { MaterialsTab } = await import('components/Supply/DocumentMovements/PageBody/Materials/MaterialsTab.tsx')
                                        return { element: <MaterialsTab/> }
                                    },
                                },
                                {
                                    id: 'warehouseDocumentMovementOrders',
                                    path: 'orders',
                                    loader: () => ({ active: 'orders' }),
                                    async lazy() {
                                        const { OrdersTab } = await import('components/Supply/DocumentMovements/PageBody/Orders/OrdersTab.tsx')
                                        return { element: <OrdersTab/> }
                                    },
                                },
                                {
                                    path: 'edit',
                                    id: 'warehouseDocumentMovementEdit',
                                    async lazy() {
                                        const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                                        return { element: <DocumentMovementBodyEdit /> }
                                    },
                                },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'warehouseInventories',
                        path: 'inventories',
                        children: [
                            {
                                id: 'warehouseInventoriesList',
                                path: '',
                                async lazy() {
                                    const { WarehouseInventoriesPage } = await import('components/Supply/Warehouses/PageBody/Inventories/WarehouseInventoriesPage.tsx')
                                    return { element: <WarehouseInventoriesPage /> }
                                },
                            },
                        ],

                    },
                    {
                        id: 'warehouseOrders',
                        path: 'orders',
                        loader: ({ params }) => ({
                            name: 'Ордера',
                            path: `supply/warehouses/${params.warehouseId}/orders`,
                        }),
                        children: [
                            {
                                id: 'warehouseFormsList',
                                path: '',
                                async lazy() {
                                    const { WarehouseOrdersPage } = await import('components/Supply/Warehouses/PageBody/Orders/WarehouseOrdersPage.tsx')
                                    return { element: <WarehouseOrdersPage /> }
                                },
                            },
                            {
                                id: 'warehouseOrderById',
                                path: ':orderId',

                                async lazy() {
                                    const { OrderPage } = await import('components/Supply/Orders/OrderPage')
                                    return { element: <OrderPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.orderId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.orderMaterialsReducer.documentMovementOrderData?.name || '',
                                    }))),
                                    path: `supply/document-movement-orders/${ params.orderId }`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/OrderBodyView.tsx')
                                            return { element: <OrderBodyView /> }
                                        },
                                    },
                                    {
                                        path: 'edit',
                                        id: 'warehouseOrderEdit',
                                        async lazy() {
                                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/OrderBodyView.tsx')
                                            return { element: <OrderBodyView /> }
                                        },
                                    },
                                    {
                                        path: 'execute',
                                        id: 'warehouseOrderExecute',
                                        async lazy() {
                                            const { OrderMaterialsTableEditWrapper } = await import('components/Supply/Orders/PageBody/OrderMaterials/OrderMaterialsTableEdit/OrderMaterialsTableEditWrapper.tsx')
                                            return { element: <OrderMaterialsTableEditWrapper /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'warehouseSupplyRequests',
                        path: 'supply-requests',
                        children: [
                            {
                                id: 'warehouseSupplyRequestsList',
                                path: '',
                                async lazy() {
                                    const { WarehouseSupplyRequestsPage } = await import('components/Supply/Warehouses/PageBody/SupplyRequests/WarehouseSupplyRequestsPage.tsx')
                                    return { element: <WarehouseSupplyRequestsPage /> }
                                },
                            },
                        ],

                    },
                    {
                        path: 'edit',
                        id: 'warehouseEdit',
                        async lazy() {
                            const { WarehousePageBodyEdit } = await import('components/Supply/Warehouses/PageBody/Info/WarehousePageBodyEdit.tsx')
                            return { element: <WarehousePageBodyEdit /> }
                        },
                    },
                ],
            },

        ],
    },
    {
        id: 'inventory',
        path: 'inventory',
        element: <Outlet />,
        loader: () => ( {
            name: 'Инвентаризации',
            path: 'supply/inventory',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { InventoriesTable } = await import('components/Supply/Inventories/InventoriesTable.tsx')
                    return { element: <InventoriesTable /> }
                },
            },
            {
                id: 'inventoryById',
                path: ':inventoryId',
                async lazy() {
                    const { InventoryPage } = await import('components/Supply/Inventories/InventoryPage.tsx')
                    return { element: <InventoryPage /> }
                },
                loader: ({ params }) => ( {
                    id: params.inventoryId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                        name: state.inventoryMaterialsReducer.inventoryData?.name || '',
                    } ))),
                    path: `supply/inventory/${ params.inventoryId }`,
                } ),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { InventoryPageBodyView } = await import('src/components/Supply/Inventories/PageBody/InventoryPageBodyView.tsx')
                            return { element: <InventoryPageBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'inventoryEdit',
                        async lazy() {
                            const { InventoryPageBodyEdit  } = await import('src/components/Supply/Inventories/PageBody/InventoryPageBodyEdit.tsx')
                            return { element: <InventoryPageBodyEdit /> }
                        },
                    },
                ],
            },
        ],
    },
    {
        id: 'documents',
        path: 'documents',
        element: <UnderConstruction />,
        loader: () => ( {
            name: 'Документы',
            path: 'supply/documents',
        } ),
    },
    {
        id: 'stocks',
        path: 'stocks',
        element: <UnderConstruction />,
        loader: () => ( {
            name: 'Остатки',
            path: 'supply/stocks',
        } ),
    },
    {
        id: 'statements',
        path: 'statements',
        async lazy() {
            const { ResourceStatementPage } = await import('components/Projects/PageBody/ResourceStatement/ResourceStatementPage.tsx')
            return { element: <ResourceStatementPage /> }
        },
        loader: () => ( {
            name: 'Ресурсные ведомости',
            path: 'supply/statements',
        } ),
        children: [
            {
                id:'resourcesStatementTable',
                path:'',
                async lazy() {
                    const { ResourcesStatementTable } = await import('components/Projects/PageBody/ResourceStatement/ResourcesStatementTable.tsx')
                    return { element: <ResourcesStatementTable /> }
                },
            },
        ]

    },
    {
        id: 'supplyJournal',
        path: 'journal',
        element: <UnderConstruction />,
        loader: () => ( {
            name: 'Журнал',
            path: 'supply/journal',
        } ),
    },
    {
        id: 'documentMovements',
        path: 'document-movements',
        element: <Outlet />,
        loader: () => ( {
            name: 'Перемещения',
            path: 'supply/document-movements',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { DocumentMovementsTable } = await import('components/Supply/DocumentMovements/DocumentMovementsTable.tsx')
                    return { element: <DocumentMovementsTable /> }
                },
            },
            {
                id: 'documentMovementNew',
                path: 'new',
                async lazy() {
                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                    return { element: <DocumentMovementPage/> }
                },
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                            return { element: <DocumentMovementBodyEdit /> }
                        },
                    },
                ],
            },
            {
                id: 'documentMovementById',
                path: ':movementId',

                async lazy() {
                    const { DocumentMovementPage } = await import('components/Supply/DocumentMovements/DocumentMovementPage.tsx')
                    return { element: <DocumentMovementPage/> }
                },
                loader: ({ params }) => ({
                    id: params.movementId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.documentMovementMaterialsReducer.documentMovementData?.name || '',
                    }))),
                    path: `supply/document-movements/${ params.movementId }`,
                }),
                children: [   {
                    index: true,
                    element: <Navigate to={ 'materials' } replace />,
                },
                {
                    id: 'documentMovementMaterialsTab',
                    path: 'materials',
                    loader: () => ({ active: 'materials' }),
                    async lazy() {
                        const { MaterialsTab } = await import('components/Supply/DocumentMovements/PageBody/Materials/MaterialsTab.tsx')
                        return { element: <MaterialsTab/> }
                    },
                },
                {
                    id: 'documentMovementOrdersTab',
                    path: 'orders',
                    loader: () => ({ active: 'orders' }),
                    async lazy() {
                        const { OrdersTab } = await import('components/Supply/DocumentMovements/PageBody/Orders/OrdersTab.tsx')
                        return { element: <OrdersTab/> }
                    },
                },
                {
                    path: 'edit',
                    id: 'documentMovementEdit',
                    async lazy() {
                        const { DocumentMovementBodyEdit } = await import('components/Supply/DocumentMovements/PageBody/DocumentMovementBodyEdit.tsx')
                        return { element: <DocumentMovementBodyEdit /> }
                    },
                },
                ],
            },
        ],
    },
    {
        id: 'documentMovementOrders',
        path: 'document-movement-orders',
        element: <Outlet />,
        loader: () => ( {
            name: 'Ордера',
            path: 'supply/document-movement-orders',
        } ),
        children: [
            {
                index: true,
                async lazy() {
                    const { OrdersTable } = await import('components/Supply/Orders/OrdersTable.tsx')
                    return { element: <OrdersTable /> }
                },
            },
            {
                id: 'orderById',
                path: ':orderId',

                async lazy() {
                    const { OrderPage } = await import('components/Supply/Orders/OrderPage')
                    return { element: <OrderPage/> }
                },
                loader: ({ params }) => ({
                    id: params.orderId,
                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                        name: state.orderMaterialsReducer.documentMovementOrderData?.name || '',
                    }))),
                    path: `supply/document-movement-orders/${ params.orderId }`,
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/OrderBodyView.tsx')
                            return { element: <OrderBodyView /> }
                        },
                    },
                    {
                        path: 'edit',
                        id: 'orderEdit',
                        async lazy() {
                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/OrderBodyView.tsx')
                            return { element: <OrderBodyView /> }
                        },
                    },
                    {
                        path: 'execute',
                        id: 'orderExecute',
                        async lazy() {
                            const { OrderMaterialsTableEditWrapper } = await import('components/Supply/Orders/PageBody/OrderMaterials/OrderMaterialsTableEdit/OrderMaterialsTableEditWrapper.tsx')
                            return { element: <OrderMaterialsTableEditWrapper /> }
                        },
                    },
                ],
            },
        ],
    },
]
