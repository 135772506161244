import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { InterFont } from 'styles/fonts/Inter'
import { darkTheme } from 'styles/themes/darkTheme'
import { lightTheme } from 'styles/themes/lightTheme'
import { DatesProvider } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'

export const ProviderMantineGlobal: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ children }) => {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
        getInitialValueInEffect: false,
    })

    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

    return (
        <ColorSchemeProvider colorScheme={ colorScheme } toggleColorScheme={ toggleColorScheme }>
            <MantineProvider
                withCSSVariables
                withGlobalStyles
                withNormalizeCSS
                theme={ colorScheme === 'light' ? lightTheme : darkTheme }
            >
                <Notifications
                    position="top-right"
                    limit={ 5 }
                    zIndex={ 2077 }/>
                <InterFont/>
                <DatesProvider settings={ { locale: 'ru', firstDayOfWeek: 1, weekendDays: [6, 0] } }>
                    { children }
                </DatesProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    )
}
