import { defaultInitialState } from 'utils/defaultInitialState.ts.ts';
import { createFormBasedSliceReducers } from 'utils/redux.ts';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { OrderMaterialsSliceInitialState } from 'components/Supply/Orders/PageBody/OrderMaterials/OrderMaterials.types';
import {
    orderMaterialsFormBodyInitialState
} from 'components/Supply/Orders/PageBody/OrderMaterials/OrderMaterials.utils.ts';
import { FindExpenseOrderMaterialResponseDto } from 'store/api/expenseOrderMaterial.tsx';
import {
    FindReceiptOrderMaterialResponseDto,
} from 'store/api/receiptOrderMaterial.tsx';
import {
    FindReturnOrderMaterialResponseDto,
} from 'store/api/returnOrderMaterial.tsx';
import { FindExpenseOrderResponseDto } from 'store/api/expenseOrder.tsx';
import { FindReturnOrderResponseDto } from 'store/api/returnOrder.tsx';
import { FindReceiptOrderResponseDto } from 'store/api/receiptOrder.tsx';
import { FindOrderStatusResponseDto } from 'store/api/orderStatuses.tsx';

const orderMaterialsSliceInitialState: OrderMaterialsSliceInitialState = {
    ...defaultInitialState,
    editableFormBody: orderMaterialsFormBodyInitialState,
    materialsForEdit: [],
    originalMaterialsForEdit: [],
    changedMaterials: [],
    documentMovementOrderStatuses: undefined,
    documentMovementOrderData: undefined,
}

const reducers = createFormBasedSliceReducers<any>()

export const orderMaterialsSlice = createSlice({
    name: 'orderMaterials',
    initialState: orderMaterialsSliceInitialState,
    reducers: {
        ...reducers,
        setMaterialsForEdit: (state: Draft<OrderMaterialsSliceInitialState>, action: PayloadAction<FindExpenseOrderMaterialResponseDto[] | FindReceiptOrderMaterialResponseDto[] | FindReturnOrderMaterialResponseDto[]>) => {
            state.materialsForEdit = action.payload
        },
        setOriginalMaterialsForEdit: (state: Draft<OrderMaterialsSliceInitialState>, action: PayloadAction<FindExpenseOrderMaterialResponseDto[] | FindReceiptOrderMaterialResponseDto[] | FindReturnOrderMaterialResponseDto[]>) => {
            state.originalMaterialsForEdit = action.payload
        },
        setChangedMaterials: (state: Draft<OrderMaterialsSliceInitialState>, action: PayloadAction<FindExpenseOrderMaterialResponseDto[] | FindReceiptOrderMaterialResponseDto[] | FindReturnOrderMaterialResponseDto[]>) => {
            state.changedMaterials = action.payload
        },
        setDocumentMovementOrderStatusesData:  (state: Draft<OrderMaterialsSliceInitialState>, action: PayloadAction<FindOrderStatusResponseDto[]>) => {
            state.documentMovementOrderStatuses = action.payload
        },
        setDocumentMovementOrderData:  (state: Draft<OrderMaterialsSliceInitialState>, action: PayloadAction<FindReceiptOrderResponseDto | FindExpenseOrderResponseDto | FindReturnOrderResponseDto>) => {
            state.documentMovementOrderData = action.payload
        },
    }
}
)


export default orderMaterialsSlice.reducer