import {
    ResourceStatementAutocompleteKey,
    ResourceStatementFormBody,
    ResourceStatementFormGlobal,
    ResourceStatementFormHeader,
    ResourceStatementMultiselectFilterParamKey
} from 'components/Projects/PageBody/ResourceStatement/ResourceStatement.types.ts';
import {
    autocompleteChangeArg,
    autocompleteFieldDefault,
    autocompleteSubmitArg,
    multiselectFieldDefault
} from 'utils/inputs.tsx';
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';

export const resourceStatementFormHeaderInitialState: ResourceStatementFormHeader = {
    name: '',
}
export const resourceStatementFormBodyInitialState: ResourceStatementFormBody = {
    materialType: autocompleteFieldDefault,
    inventoryType: multiselectFieldDefault,
}

export const resourceStatementFormBodyInitialState1: ResourceStatementFormGlobal = {
    name: '',
    materialType: autocompleteFieldDefault,
    inventoryType: multiselectFieldDefault,
}


export const onMaterialTypesAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ResourceStatementAutocompleteKey, ResourceStatementFormGlobal>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onMaterialTypesAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ResourceStatementAutocompleteKey, ResourceStatementFormGlobal>) => (label: string) => {
    if (formData[key]?.name!==label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const onInventoryTypeMultiselectChange = ({ formData, key, setFormValue }: InputChangeArg<ResourceStatementMultiselectFilterParamKey, ResourceStatementFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${key}.name`, label)
    }
}
export const onInventoryTypeMultiselectSubmit = ({ key, setFormValue }: InputSubmitArg<ResourceStatementMultiselectFilterParamKey, ResourceStatementFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${key}.ids`, valueIds)
}

