import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs.tsx';
import { OrderMaterialsAutocompleteKey, OrderMaterialsFormBody } from 'components/Supply/Orders/PageBody/OrderMaterials/OrderMaterials.types.ts';
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs.ts';

// *
// STORE
// *

export const orderMaterialsFormBodyInitialState: OrderMaterialsFormBody = {
    executor: autocompleteFieldDefault,
}

export const onOrderMaterialsBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<OrderMaterialsAutocompleteKey, OrderMaterialsFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onOrderMaterialsBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<OrderMaterialsAutocompleteKey, OrderMaterialsFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
